var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-mb-6"},[_c('div',{},[_c('nav',{staticClass:"tw--mb-px tw-flex tw-space-x-4"},_vm._l((_vm.tabs),function(tab){return _c('router-link',{key:tab.id,staticClass:"group tw-inline-flex tw-items-center tw-py-2 tw-px-1 tw-border-b-2 tw-font-normal tw-text-sm",class:[
                    _vm.currentRoute === tab.to.name
                    ? _vm.textActiveClasses
                    : _vm.textInactiveClasses,
                ],attrs:{"to":tab.to,"replace":""}},[(tab.icon)?_c('icon',{staticClass:"tw--ml-0.5 tw-mr-2 tw-h-5 tw-w-5",class:[
                        _vm.currentRoute === tab.to.name
                        ? _vm.iconActiveClasses
                        : _vm.iconInactiveClasses,
                    ],attrs:{"icon":tab.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(tab.label))])],1)}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }