<template>
          <!-- Profile header -->
          <div>
            <div class="tw-max-w-5xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
              <div class="sm:tw-flex sm:tw-space-x-5">
                <div class="tw-flex">
                  <Avatar class="tw-h-24 tw-w-24 tw-rounded-full tw-ring-2 tw-ring-white sm:tw-h-32 sm:tw-w-32" :username="fullName" size="64"/>
                </div>
                <div class="tw-mt-4 sm:tw-flex-1 sm:tw-min-w-0 sm:tw-flex sm:tw-items-center sm:tw-justify-end sm:tw-space-x-6 sm:tw-pb-1">
                  <div class="sm:tw-hidden 2xl:tw-block tw-mt-4 tw-min-w-0 tw-flex-1">
                    <h1 class="tw-text-2xl tw-font-normal tw-text-gray-900 tw-truncate" style="margin-bottom: 0">
                      {{ fullName }}
                    </h1>
                    <h3 class="tw-text-sm tw-font-medium tw-text-gray-400 tw-truncate">
                      <icon :icon="['fal', 'hashtag']" class="tw-mr-1"/>Team
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
</template>

<script>
import Avatar from "vue-avatar";
import { mapGetters } from 'vuex'

export default {
    components: {
        Avatar
    },
     computed: {
        ...mapGetters("user", [
            'fullName',
            'currentTeam',
        ]),
        teamName() {
            this.$store.getters['user/currentTeam'].name
        }
    },
}
</script>

<style>

</style>