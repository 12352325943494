<template>
  <Layout>
    <PageHeader slot="header" title="Team">
      <template slot="page-buttons">
        <!-- <router-link :to="{ name: 'New incident' }" class="btn-gray">
            <icon :icon="['fal', 'cog']" class="tw-mr-1" />Setup
        </router-link> -->
      </template>
    </PageHeader>

    <TeamsTabs v-if="!mobile" :tab="tab" @update-tab="updateTab" />
    <router-view></router-view>
  </Layout>
</template>

<script>
import axios from "axios";
import TeamsTabs from "@/components/Team/TeamsTabs.vue";
import ProfileHeader from "@/components/Settings/ProfileHeader.vue";
import { isMobile } from "mobile-device-detect"

export default {
  name: "team-users",
  components: { 
    TeamsTabs,
    ProfileHeader
  },
  data() {
    return {
      tab: 1,
    };
  },
  computed: {
      mobile() {
          return isMobile
      }
  },
  methods: {
    updateTab(val) {
      console.log("click");
      this.tab = val;
    },
  },
  created() {
  }
};
</script>

<style>
</style>