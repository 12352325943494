<template>
    <div class="tw-mb-6">
        <div class="">
            <nav class="tw--mb-px tw-flex tw-space-x-4">
                <router-link
                    v-for="tab in tabs"
                    :key="tab.id"
                    :to="tab.to"
                    class="group tw-inline-flex tw-items-center tw-py-2 tw-px-1 tw-border-b-2 tw-font-normal tw-text-sm"
                    v-bind:class="[
                        currentRoute === tab.to.name
                        ? textActiveClasses
                        : textInactiveClasses,
                    ]"
                    replace
                >
                    <icon
                        v-if="tab.icon"
                        :icon="tab.icon"
                        class="tw--ml-0.5 tw-mr-2 tw-h-5 tw-w-5"
                        v-bind:class="[
                            currentRoute === tab.to.name
                            ? iconActiveClasses
                            : iconInactiveClasses,
                        ]"
                    />
                    <span>{{ tab.label }}</span>
                </router-link>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    props: ["tab"],
    data() {
        return {
            tabsList: [
                {
                    id: 1,
                    label: "General",
                    to: { name: "Team home" },
                    icon: null,
                    flag: null
                },
                {
                    id: 2,
                    label: "Team members",
                    to: { name: "Users" },
                    icon: null,
                    flag: null,
                },
                {
                    id: 3,
                    label: "Usage",
                    to: { name: "Usage" },
                    icon: null,
                    flag: null,
                },
                {
                    id: 4,
                    label: "Integrations",
                    to: { name: "Integrations" },
                    icon: null,
                    flag: null,
                }
            ],
        };
    },
    computed: {
        tabs() {
            return this.tabsList.filter((tab) => {
                //  NOTE: Can use feature flags here in future.
                return true;
            });
        },
        textActiveClasses() {
            return "tw-border-orange-500 tw-text-sm tw-text-orange-600";
        },
        textInactiveClasses() {
            return "tw-border-transparent tw-text-sm tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300";
        },
        iconActiveClasses() {
            return "tw-text-orange-500";
        },
        iconInactiveClasses() {
            return "tw-text-gray-400 group-hover:tw-text-gray-500";
        },
        currentRoute() {
            return this.$route.name;
        },
    },
    methods: {},
};
</script>

<style>
</style>
